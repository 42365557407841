import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
} from "../Utils/CssStyleTemplateUtil";
import { s } from "../Utils/Lang";
import { StaticImage } from "../Components/StaticImage";
import { isDesktop, isPhone } from "../Utils/WindowUtil";
import * as Widget from "../Components/Widget";

class ProjectListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { navActions } = this.props;
    let { projects } = this.props.pageContext;

    return (
      <Wrapper>
        <div className="introduction-section">
          <h2 className="title">{s("project-title")}</h2>
          <p>{s("project-introduction")}</p>
          <Widget.Button
            css={`
              margin: 5px 0px 0px;
              margin-top: 30px !important;
              background-color: #f36a26;
            `}
            onClick={() =>
              window &&
              window.open(
                "https://revteltech.pse.is/goto_revtel_medium_from_website",
                "_blank"
              )
            }
          >
            更多開發分享
          </Widget.Button>
        </div>
        <div className="project-list-section">
          <div className="project-items-container">
            {projects.map((item, idx) => (
              <div
                key={idx}
                className="project-item"
                onClick={() => {
                  if (item.description_tw && this.props.lang === "tw") {
                    navActions.push(`/project/${item.id}`);
                  } else if (item.linkURL) {
                    window && window.open(item.linkURL, "_blank");
                  }
                }}
                style={{
                  cursor:
                    (item.description_tw && this.props.lang === "tw") ||
                    item.linkURL
                      ? "pointer"
                      : "not-allowed",
                  padding: 2,
                  backgroundColor: "#EEEEEE",
                }}
              >
                <ProjectItem item={item} key={idx} />
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & h2 {
      letter-spacing: 2px;
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 20px;
    }

    & p {
      font-size: 14px;
      ${ParagraphFontCss}
      white-space: pre-wrap;
      text-align: justify;
      line-height: 20px;
    }

    & button {
      margin-top: 40px;
      align-self: flex-start;
    }
  }

  .project-list-section {
    flex-grow: 1;

    & .filter-bar-container {
      min-height: 50px;
      display: flex;
      align-items: stretch;
    }
    & .project-items-container {
      display: flex;
      flex-wrap: wrap;
      & .project-item {
        flex-basis: calc(100% / 2);
      }
    }
  }

  @media screen and (max-width: 900px) {
    .introduction-section {
      padding: 30px 40px 40px;
      flex-basis: initial;

      flex-grow: 1;
      & h2 {
        letter-spacing: 2px;
        text-align: left;
      }

      & button {
        align-self: center;
      }
    }

    .project-list-section {
    }
  }

  @media screen and (max-width: 500px) {
    .introduction-section {
      padding: 30px 25px 50px;
      & button {
        margin-top: 30px;
      }
    }
    .project-list-section {
      & .filter-bar-container {
      }
      & .project-items-container {
        & .project-item {
          flex-basis: 100%;
        }
      }
    }
  }
`;

class ProjectItem extends Component {
  render() {
    let { item, lang } = this.props;
    return (
      <ProjectItemWrapper>
        <StaticImage
          name={item.cover_img}
          alt={item.alt}
          style={{ backgroundSize: "cover", height: 250 }}
        />
        <div className="info-container">
          <div className="title">{item[`title_${lang}`]}</div>
          <div className="date">{item.created}</div>
        </div>
      </ProjectItemWrapper>
    );
  }
}

const ProjectItemWrapper = styled.div`
  position: relative;
  height: 100%;

  & .info-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 50%;
    width: 100%;
    background-image: linear-gradient(
      0deg,
      #4e4e4e 0%,
      rgba(170, 170, 170, 0.01) 89%
    );
    padding: 0px 20px 15px 20px;
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    color: white;
    letter-spacing: 1px;
    ${HeaderFontCss}
    justify-content: space-between;

    & .title {
    }
    & .date {
    }
  }
`;

ProjectItem = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(ProjectItem);

export default withPage(
  connect((state, ownProps) => ({ ownProps }), ActionCreator)(ProjectListPage)
);
